<app-title title="CONTROLE DE USUÁRIOS"></app-title>

<div class="p-col" style="max-width: 100%;">
    <p-card>
        <button pButton [disabled]="!checkPermission('user.add')" icon='pi pi-plus' (click)="openForm(null)"
            class="p-button-rounded primaryButton" label="Adicionar"
            title="Clique para abrir o formulário para adicionar usuário"></button>
        <div class="p-col"></div>
        <div class="card" id="table-card">
            <p-table [value]="users" [scrollable]="true" [resizableColumns]="true" [rows]="5" [rowHover]="true"
                dataKey="id" [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <!--<h4 class="p-m-0">Busca</h4>-->
                        <span class="p-input">
                            <label for="filterName" class="p-mr-2">Nome</label>
                            <input pInputText id="filterName" [(ngModel)]="userFilter.name" type="text"
                                (keyup.enter)="searchUsers()" />
                        </span>
                        <span class="p-input">
                            <label for="filterEmail" class="p-mr-2">E-mail</label>
                            <input pInputText id="filterEmail" [(ngModel)]="userFilter.email" type="text"
                                (keyup.enter)="searchUsers()" />
                        </span>
                        <span class="p-input">
                            <label for="filterCpf" class="p-mr-2">CPF</label>
                            <p-inputMask id="filterCpf" unmask="true" [(ngModel)]="userFilter.cpf" mask="999.999.999-99"
                                type="text" (keyup.enter)="searchUsers()"></p-inputMask>
                        </span>
                        <span class="p-input">
                            <label for="filterProfile" class="p-mr-2">Perfil</label>
                            <p-dropdown id="filterProfile" [options]="profilesFilter" optionLabel="name"
                                [(ngModel)]="userFilter.profile" (onChange)="searchUsers()"></p-dropdown>
                        </span>
                        <span class="p-input" *ngIf="showCompanyFilter">
                            <label for="filterCompany" class="p-mr-2">Companhia</label>
                            <p-dropdown id="filterCompany" [options]="companiesFilter" optionLabel="name"
                                [(ngModel)]="companyDropDown" (onChange)="searchUsers()"></p-dropdown>
                        </span>
                        <button pButton (click)="searchUsers()" label="Filtrar" icon='pi pi-filter'
                            class="p-button-rounded primaryButton"></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width:40px">
                        <col style="width:140px">
                        <col style="width:80px">
                        <col style="width:150px">
                        <col style="width:100px">
                        <col style="width:90px">
                        <col style="width:40px">
                        <col style="width:40px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>E-mail</th>
                        <th>Perfil</th>
                        <th>Companhia</th>
                        <th>Editar</th>
                        <th>Status</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td>{{user.id}}</td>
                        <td>{{user.name}}</td>
                        <td>{{formatCpf(user.cpf)}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.profile.name}}</td>
                        <td>{{user.company ? user.company.name : ''}}</td>
                        <td>
                            <button pButton [disabled]="!checkPermission('user.update')" pRipple icon="pi pi-pencil"
                                class="secundaryButton p-button-rounded p-mr-2" (click)="openForm(user)"></button>
                        </td>
                        <td>
                            <button pButton [disabled]="!checkPermission('user.change-status')"
                                (click)="confirmChangeStatusUser(user)" class="secundaryButton p-button-rounded p-mr-2"
                                pRipple [icon]="user.enabled ? 'pi pi-eye' : 'pi pi-eye-slash'"
                                [title]="'Clique para ' + (user.enabled ? 'Desabilitar' : 'Habilitar')">
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" *ngIf="withoutPermission">
                    <tr>
                        <td colspan="8" style="text-align: center">{{withoutPermission}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total: {{ count }} usuário{{ count > 1 ? 's' : '' }}
                    </div>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" (onPageChange)=changePage($event) [totalRecords]="count"></p-paginator>
        </div>
    </p-card>

    <p-dialog [(visible)]="userDialog" [style]="{width: '500px'}" header="Detalhes do Usuário" [modal]="true"
        styleClass="p-fluid" (onHide)="hideDialog()">
        <ng-template pTemplate="content">
            <div class="p-field">
                <label for="name">Nome</label>
                <input type="text" pInputText id="name" [(ngModel)]="user.name" required autofocus />
                <small class="p-error" *ngIf="submitted && !user.name">Nome de usuário é
                    obrigatório.</small>
            </div>
            <div class="p-field">
                <label for="cpf">CPF</label>
                <p-inputMask id="cpf" unmask="true" [(ngModel)]="user.cpf" mask="999.999.999-99" type="text" required
                    autofocus>
                </p-inputMask>
                <small class="p-error" *ngIf="submitted && !user.cpf">CPF é obrigatório.</small>
            </div>
            <div class="p-field">
                <label for="email">E-mail</label>
                <input type="text" pInputText id="email" [(ngModel)]="user.email" required autofocus />
                <small class="p-error" *ngIf="submitted && !user.email">E-mail é obrigatório.</small>
            </div>
            <div class="p-grid">
                <div class="p-col">
                    <div class="p-field">
                        <label class="p-mb-3">Companhia</label>
                        <div class="p-field-checkbox" *ngFor="let company of companiesForm">
                            <p-radioButton name="company" [value]="company.name" [(ngModel)]="user.company.name">
                            </p-radioButton>
                            <label>{{company.name}}</label>
                        </div>
                    </div>
                </div>
                <div class="p-col">
                    <div class="p-field">
                        <label class="p-mb-3">Tipo de Perfil</label>
                        <div class="p-field-checkbox" *ngFor="let profile of profiles">
                            <p-radioButton name="profile" [value]="profile.name" [(ngModel)]="user.profile.name">
                            </p-radioButton>
                            <label>{{profile.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-field">
                <div *ngIf="user.creationDate">
                    <label class="p-mr-1">Data/hora criação:</label>
                    <label>{{user.creationDate | date:'dd/MM/yyyy HH:mm:ss'}}</label>
                </div>
                <div *ngIf="user.lastLoginDate">
                    <label class="p-mr-1">Último login:</label>
                    <label>{{user.lastLoginDate | date:'dd/MM/yyyy HH:mm:ss'}}</label>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <app-button classes="p-mr-4" [disabled]="!checkPermission('user.reset-password') || !user.id"
                label="Redefinir Senha" type="rounded" theme="secondary" icon="refresh"
                (onClick)="resetPassword(user)"></app-button>

            <app-button classes="p-mr-4" label="Cancelar" type="rounded" theme="third" icon="cancel"
                title="Clique para alterar a senha" (onClick)="hideDialog()"></app-button>

            <app-button label="Salvar" type="rounded" theme="primary" icon="check" (onClick)="saveUser()"></app-button>
        </ng-template>
    </p-dialog>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>