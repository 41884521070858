import { Component, OnInit } from '@angular/core'
import { ConfirmationService } from 'primeng/api'
import { SnackBarService } from 'src/app/core/components/snack-bar.service'
import { HasLoggedUserPermission } from 'src/app/core/domain/usecases/has-logged-user-permission'
import { IsLoggedUserAdministrator } from 'src/app/core/domain/usecases/is-logged-user-administrator'
import { CompanyHelper } from 'src/app/core/helpers/company.helper'
import { Constants } from 'src/app/core/helpers/constants'
import { GenericHelper } from 'src/app/core/helpers/generic.helper'
import { CompanyService } from 'src/app/core/service/company.service'
import { CompanyNew } from 'src/app/modules/companies/domain/models/company-new.model'
import { environment } from 'src/environments/environment'
import { Profile } from '../../domain/models/profile.model'
import { User, UserList } from '../../domain/models/user.model'
import { FilterUsers } from '../../domain/usecases/filter-users'
import { GetAllUserProfiles } from '../../domain/usecases/get-all-user-profiles'
import { ResetUserPassword } from '../../domain/usecases/reset-user-password'
import { SaveUpdateUser } from '../../domain/usecases/save-update-user'
import { UpdateUserStatus } from '../../domain/usecases/update-user-status'

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css'],
})
export class ListUsersComponent implements OnInit {
  userDialog: boolean
  submitted = false
  valuePage = 0
  userFilter: User = new User()
  specialPermission: boolean

  users: User[] = []
  companiesForm: CompanyNew[] = []
  profiles: Profile[] = []
  profilesFilter: Profile[] = []
  companiesFilter: CompanyNew[] = []
  showCompanyFilter: boolean

  count = 0

  userList: UserList

  user = new User()
  withoutPermission = ''
  companyDropDown: CompanyNew

  constructor(
    private companyService: CompanyService,
    private snackBar: SnackBarService,
    private confirmationService: ConfirmationService,
    private hasLoggedUserPermission: HasLoggedUserPermission,
    private genericHelper: GenericHelper,
    private companyHelper: CompanyHelper,
    private isLoggedUserAdministrator: IsLoggedUserAdministrator,
    private saveUpdateUser: SaveUpdateUser,
    private filterUsers: FilterUsers,
    private updateUserStatus: UpdateUserStatus,
    private resetUserPassword: ResetUserPassword,
    private getAllUserProfiles: GetAllUserProfiles
  ) {}

  ngOnInit() {
    this.showCompanyFilter = this.isLoggedUserAdministrator.execute()
    this.specialPermission = this.checkPermission(
      environment.configManagerPermission
    )
    this.listAllUsers()
    this.listAllProfiles()
    this.listAllCompanies()
  }

  listAllProfiles() {
    if (this.checkPermission('profile.list')) {
      this.getAllUserProfiles.execute().subscribe((data) => {
        this.profiles = data
        this.profilesFilter.push(new Profile(Constants.optionTodos))
        this.profilesFilter = this.profilesFilter.concat(this.profiles)
      })
    } else {
      this.snackBar.warn('Sem permissão para listar perfis!', 5)
    }
  }

  listAllCompanies() {
    if (this.checkPermission('company.list')) {
      this.companyService.listAllCompanies().subscribe((data) => {
        this.companiesForm = data.content

        if (this.isLoggedUserAdministrator.execute()) {
          this.companiesFilter = this.companiesFilter.concat(data.content)
          this.companiesFilter.push(new CompanyNew(Constants.optionTodas))
          this.companyDropDown = this.companiesFilter.find(
            (c) => c.name === Constants.optionTodas
          )
          return
        }

        this.companiesFilter = []
        const company = this.companyHelper.getCompanyFromLoggedUser()

        if (company.name === '') {
          this.companiesFilter.push(new CompanyNew(Constants.optionNenhuma))
          return
        }

        this.companyDropDown = <any>company //TODO: fix the type when adjuste the company model on other
        this.companiesForm = this.companiesForm.filter(
          (c) => c.name === company.name
        )
        this.companiesFilter = this.companiesFilter.concat(this.companiesForm)
      })
    } else {
      this.snackBar.warn('Sem permissão para listar companhias!', 5)
    }
  }

  listAllUsers() {
    this.userFilter = new User()
    this.searchUsers()
  }

  searchUsers() {
    if (this.checkPermission('user.list')) {
      this.withoutPermission = ''

      if (
        !this.userFilter.profile ||
        this.userFilter.profile.name === Constants.optionTodos
      ) {
        this.userFilter.profile = new Profile()
      }

      if (
        !this.companyDropDown ||
        this.companyDropDown.name === Constants.optionTodas
      ) {
        this.userFilter.company = new CompanyNew()
      } else {
        this.userFilter.company = this.companyDropDown
      }

      this.userFilter.nullCompany =
        this.userFilter.company &&
        this.userFilter.company.name === Constants.optionNenhuma
      this.filterUsers.execute({ data: this.userFilter }).subscribe((data) => {
        this.userList = data
        this.users = this.userList.list
        this.count = this.userList.count
      })
    } else {
      this.withoutPermission = 'Sem permissão para listar usuários!'
    }
  }

  openForm(user: User) {
    if (user) {
      this.user = this.genericHelper.deepCopy(user)
    } else {
      this.user = new User()
      this.user.profile = new Profile()
    }
    if (!this.user.company) {
      this.user.company = new CompanyNew()
      if (this.specialPermission) {
        this.user.company.name = Constants.optionNenhuma
      }
    }
    this.userDialog = true
  }

  hideDialog() {
    this.userDialog = false
    this.submitted = false
  }

  changePage(event) {
    this.filterUsers
      .execute({ data: this.userFilter, page: event.page })
      .subscribe((data) => {
        this.userList = data
        this.users = this.userList.list
        this.count = this.userList.count
        this.valuePage = event.page
      })
  }

  saveUser() {
    this.submitted = true
    if (
      !this.specialPermission &&
      !this.showCompanyFilter &&
      (!this.user.company || !this.user.company.name)
    ) {
      this.snackBar.error(
        'Dados de formulário inválidos, a companhia é obrigatória!',
        5,
        'top',
        'right'
      )
      return
    }

    this.saveUpdateUser.execute(this.user).subscribe(
      (user) => {
        if (user.password) {
          this.hideDialog()
          this.listAllUsers()
          this.snackBar.success(
            user.name +
              ' salvo! Por favor, anote a senha inicial: ' +
              user.password,
            30,
            'top',
            'right'
          )
        } else {
          this.hideDialog()
          this.listAllUsers()
          this.snackBar.success(
            user.name + ' atualizado com sucesso',
            15,
            'top',
            'right'
          )
        }
      },
      (error) => {
        if (error.status === 400) {
          this.snackBar.error(
            'Dados de formulário inválidos!',
            5,
            'top',
            'right'
          )
        } else if (error.status === 405) {
          this.snackBar.error(
            'Sem permissões para esta ação!',
            5,
            'top',
            'right'
          )
        } else {
          this.snackBar.error('Erro desconhecido!', 5, 'top', 'right')
        }
      }
    )
  }

  confirmChangeStatusUser(user) {
    this.confirmationService.confirm({
      message:
        'Realmente deseja <b>' +
        (user.enabled ? 'Desabilitar' : 'Habilitar') +
        '</b> o usuário <b>' +
        user.name +
        '</b>?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'yesButton',
      rejectButtonStyleClass: 'noButton',
      rejectLabel: 'Não',
      accept: () => {
        this.changeStatus(user)
      },
    })
  }

  changeStatus(user) {
    this.updateUserStatus.execute(user).subscribe(
      () => {},
      (error) => {
        if (error.status == 200) {
          this.snackBar.success(
            'Usuário ' + error.error.text,
            10,
            'top',
            'right'
          )
          this.filterUsers
            .execute({ data: this.userFilter, page: this.valuePage })
            .subscribe((data) => {
              this.userList = data
              this.users = this.userList.list
              this.count = this.userList.count
            })
        } else if (error.status == 404) {
          this.snackBar.error('Usuário não encontrado!', 5, 'top', 'right')
        } else {
          this.snackBar.error(
            'Erro ao tentar mudar o status do usuário!',
            5,
            'top',
            'right'
          )
        }
      }
    )
  }

  resetPassword(user) {
    this.confirmationService.confirm({
      message:
        'Realmente deseja <b>Redefinir</b> a senha do usuário <b>' +
        user.name +
        '</b>?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.resetUserPassword.execute(user.id).subscribe(
          () => {},
          (error) => {
            if (error.status == 200) {
              this.snackBar.success(
                'Senha redefinida! Por favor, anote a nova senha: ' +
                  error.error.text,
                20,
                'top',
                'right'
              )
            } else if (error.status == 400) {
              this.snackBar.error(
                JSON.stringify(error.error),
                5,
                'top',
                'right'
              )
            } else {
              this.snackBar.error('Erro desconhecido!', 5, 'top', 'right')
            }
          }
        )
      },
    })
  }

  formatCpf(cpf: string): string {
    return this.genericHelper.formatCpf(cpf)
  }

  checkPermission(Permission: string): boolean {
    return this.hasLoggedUserPermission.execute(Permission)
  }
}
