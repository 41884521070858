import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginResponse } from '../domain/models/login-response';
import { UserCredentials } from '../domain/models/user-credentials';
import { ChangePassword } from '../domain/models/change-password';
import * as bcrypt from 'bcryptjs';

export const InterceptorSkipHeader = 'X-Skip-Interceptor'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userUrl: string;
  private apiUrl = `${environment.apiUrl}`
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      InterceptorSkipHeader,
    }),
  }

  constructor(private httpClient: HttpClient) {}

  logIn(userAuth: UserCredentials): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(
      `${this.apiUrl}` + 'auth/login',  
      userAuth,
      this.httpOptions
    )
  }

  getAccessToken() {
    return sessionStorage.getItem('token');
  }

  public async changePassword(changePassword: ChangePassword, oldPassAlreadyEncrypted?: boolean): Promise<any> {
    let oldPassEncrypted = oldPassAlreadyEncrypted ? changePassword.passwordOld : bcrypt.hashSync(changePassword.passwordOld, environment.bcryptSalt);
    let newPassEncrypted = bcrypt.hashSync(changePassword.passwordNew, environment.bcryptSalt);
    let payload = {
      passwordOld: oldPassEncrypted,
      passwordNew: newPassEncrypted
    };
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.getAccessToken());
    return this.httpClient.put<any>(this.apiUrl + 'user/change-password', payload, this.httpOptions).toPromise();
  }

}
