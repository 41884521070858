import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { ChangePassword } from '../../domain/models/change-password' 
import { UserAuthorized } from '../../domain/models/user-authorized'
import { StorageHelper } from '../../helpers/storage.helper'
import { AuthService } from '../../service/auth.service'
import { MessageService } from 'primeng/api'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  userAuthorized: UserAuthorized
  passwordDialog: boolean
  submitted: boolean
  changePassword: ChangePassword
  mustDefinePassword: boolean
  permissions: string[] = []
  minimumSize: number
  requirements: string[] = []
  requirementsAllOk: boolean
  requirementsOk: boolean[] = []
  requirementsTitle: string[] = []
  language: string
  userName: string


  constructor(
    private router: Router,
     private storageHelper: StorageHelper,
     private authService: AuthService,
     private messageService: MessageService
    ) {}

  ngOnInit(): void {
    this.loadRequirementsPassword()
    this.userAuthorized = new UserAuthorized()
    this.userAuthorized.name = this.storageHelper.getBy('auth_name') // to shows user name at header page
    let rp = this.storageHelper.removeBy('rp')
    if (rp) {
      // when a user has a default password, it must redefine password (rp) after login
      this.mustDefinePassword = true
      this.showChangePasswordDialog()
      this.changePassword.passwordOld = rp
    }
  }


  checkPasswordRequirements(value: string) {
    value = value.trim()
    let ok
    this.requirementsOk = []
    ok = value.length >= this.minimumSize
    this.requirementsOk.push(ok)
    let req
    // for each requirement rule, checks if password typed was answered
    for (let i = 0; i < this.requirements.length; i++) {
      req = this.requirements[i]
      let regexp = new RegExp(
        req == 'A'
          ? '[A-Z]'
          : req == 'a'
          ? '[a-z]'
          : req == '0'
          ? '[0-9]'
          : '[^A-Za-z^0-9]'
      )
      ok = regexp.test(value)
      this.requirementsOk.push(ok)
    }
    // checks if all requirements was answered
    this.requirementsAllOk = this.requirementsOk.every(function (ok) {
      return ok
    })
  }

  loadRequirementsPassword() {
    let temp = `${environment.requirementsPassword}`
    let array = temp.split('_')
    this.minimumSize = Number(array[0])
    temp = array[1]
    if (temp.indexOf('A') >= 0) {
      this.requirements.push('A')
      this.requirementsTitle.push('Pelo menos uma letra maíuscula')
    }
    if (temp.indexOf('a') >= 0) {
      this.requirements.push('a')
      this.requirementsTitle.push('Pelo menos uma letra minúscula')
    }
    if (temp.indexOf('0') >= 0) {
      this.requirements.push('0')
      this.requirementsTitle.push('Pelo menos um número')
    }
    if (temp.indexOf('@') >= 0) {
      this.requirements.push('@')
      this.requirementsTitle.push('Pelo menos um símbolo')
    }
  }

  showChangePasswordDialog() {
    this.changePassword = new ChangePassword()
    this.passwordDialog = true
  }

  async sendChangePassword() {
    this.submitted = true
    if (
      this.changePassword.passwordOld &&
      this.changePassword.passwordNew &&
      this.changePassword.passwordNew ==
        this.changePassword.passwordNewConfirm &&
      this.requirementsAllOk
    ) {
       await this.authService
        .changePassword(this.changePassword)
        .then(() => {})
        .catch((error) => {
          console.log(error)
          if (error.status == 200 
            && error.message.endsWith('/user/change-password')) {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'Senha atualizada!',
              life: 4000,
            })
            alert("Senha alterada com sucesso!")
            this.hideDialog()
            
          } else if (error.status == 400) {
            this.messageService.add({
              severity: 'error',
              summary: 'Atualização de senha',
              detail: error.error,
              life: 4000,
            })
            alert("Senha Atual incorreta!")
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro desconhecido',
              detail: error.error.error,
              life: 4000,
            })
            alert("Não foi possível realizar a alteração solicitada, tente novamente!")
          }
        })
    }
  }

  hideDialog() {
    this.passwordDialog = false
    this.submitted = false
    this.requirementsOk = []
  }

  logout() {
    this.router.navigate(['login']).then(() => this.storageHelper.clearAll())
    let recaptcha = document.getElementsByClassName(
      'grecaptcha-badge'
    ) as HTMLCollectionOf<HTMLElement>
    if (recaptcha && recaptcha.item(0) && recaptcha.item(0).style)
      recaptcha.item(0).style.visibility = 'visible' // shows recaptcha again at login page
  }

  home() {
    this.router.navigate(['transacttions/dashboard'])
  }

  isRequirementOk(index: number) {
    return this.requirementsOk.length > index && this.requirementsOk[index]
  }
}
